import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import $ from 'jquery';

function Menu(){
    const location =  useLocation();
        const {pathname} =location;
        const splitLocation = pathname.split("/");
    const handleClick = () => {
        // force a re-render
        this.forceUpdate();
      };

      const openMenu = () => {
        if ($('.main-navigation .navigation-box').length) {
            var subMenu = $('.navigation-box ul');
            subMenu.parent('li').children('Navlink').append(function() {
                return '<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>';
            });
            var mainNavToggler = $('#main_MenuNav');
            var subNavToggler = $('.main-navigation .sub-nav-toggler');
            // mainNavToggler.click(
                // function () {
                var Self = $(this);
                var menu = Self.closest('.header-navigation').find(Self.data('target'));
                // var menu = document.getElementsByClassName("main-navigation");
                // console.log("Test");
                // $(menu).slideToggle();
                // $(menu).toggleClass('showen');
                $("#main_NavIgation").slideToggle();
               $("#main_NavIgation").toggleClass("showen");
                return false;
            // });
            // subNavToggler.on('click', function() {
            //     var Self = $(this);
            //     Self.parent().parent().children('ul').slideToggle();
            //     return false;
            // });
        }
    }   
    
    return(
        <div>
            <div className="page-wrapper">
        <div className="topbar-two">
            <div className="container">
                <div className="topbar-two__left">
                    <a className="topbar-two__link" href="tel:+6013-7315868"><i className="fa fa-phone-alt"></i>Consultation<span>+6013-7315868</span></a>
                    <a className="topbar-two__link" href="tel:+6013-7315868"><i className="fa fa-clock"></i>Isnin - Jumaat : 09:00 am - 05:00 pm</a>
                    <a className="topbar-two__link" href="mailto:hsc.kanun@gmail.com" target="_blank"><i className="fa fa-envelope-open"></i>hsc.kanun@gmail.com</a>
                </div>
                <div className="topbar-two__right">
                    <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn header-booking__btn">Temujanji</a>
                </div>
            </div>
        </div>

        <header className="site-header site-header__header-two  ">
            <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky">
                <div className="container clearfix">
                    
                    <div className="logo-box">
                        <NavLink onClick={handleClick} className="navbar-br/and" to='/'>
                        <img src="assets/images/logo/hars_black-removebg(h70).png" className="main-logo m-3" alt="Awesome Image" />
                        </NavLink>
                        <button className="menu-toggler" data-target=".main-navigation" id='main_MenuNav' onClick={openMenu}>
                            <span className="fa fa-bars"></span>
                        </button>
                    </div>
                    
                    <div className="main-navigation" id='main_NavIgation'>
                        <ul className=" navigation-box ">
                            <li className={`${splitLocation [1] === "" ? "current" : ""}`}>
                                <NavLink onClick={handleClick} to='/'>Utama</NavLink>
                            </li>
                            <li className={`${splitLocation [1] === "about" ? "current" : ""}`}>
                                <NavLink onClick={handleClick} to='/about'>Tentang Kami</NavLink>
                            </li>

                            <li className={`${splitLocation [1] === "maintenance" ? "current" : ""}`}>
                                <NavLink onClick={handleClick} to='/maintenance'>Jelajah Kanun</NavLink>
                                {/* <ul>
                                    <li><a href="service-1.html">Practice 01</a></li>
                                    <li><a href="service-2.html">Practice 02</a></li>
                                </ul> */}
                            </li>

                            <li className={`${splitLocation [1] === "service" ? "current" : ""}`}>
                                <NavLink onClick={handleClick} to='/service'>Khidmat Guaman</NavLink>
                            </li>
                            {/* <li>
                                <NavLink to='/blog'>News</NavLink>
                                <ul>
                                    <li><a href="blog-classic.html">News Classic</a></li>
                                    <li><a href="blog-grid.html">News Grid</a></li>
                                    <li><a href="blog-post.html">News Details</a></li>
                                </ul>
                            </li> */}
                            <li className={`${splitLocation [1] === "contact" ? "current" : ""}`}>
                                <NavLink onClick={handleClick} to='/contact'>Hubungi Kami</NavLink>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="right-side-box">
                        <a href="#" className="header__search search-popup__toggler"><i className="fas fa-search"></i></a>
                        <a href="#" className="header__sidebar-toggler  side-menu__toggler"><i className="fas fa-bars"></i></a>
                    </div> */}
                </div>
                
            </nav>
        </header>
        </div>
        </div>
    )
}

export default Menu;