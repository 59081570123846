import React,{ useState, useEffect } from 'react';
import Header from '../layouts/Header';
// import Footer from '../layouts/Footer';
const $ = require("jquery");

function Home() {
    
  return (
    <div>
    {/* <div className="page-wrapper"> */}
    <Header/>
    <section className="slider-one">
            <div className="slider-one__carousel owl-carousel owl-theme">
                <div className="item slider-one__slide slider-one__slider-1" style={{backgroundImage: "url(/assets/images/background/CustomBG1.png)"}}>
                    <div className="container">
                        <p className="slider-one__tag-line">Torchbearers for Syariah & Law.</p>
                        <h2 className="slider-one__title">Peguambela <br/> Peguamcara <br/> Peguam Syarie <br/> <span style={{color: '#D4AF37'}}> ANDA </span></h2>
                        <p className="slider-one__text">Kami bersedia membantu individu dan syarikat korporat di Malaysia.<br/>  Segera rungkaikan isu perundangan yang membelenggu anda. </p>
                        
                        <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn slider-one__btn">Consultation<i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
                <div className="item slider-one__slide slider-one__slider-2" style={{backgroundImage: "url(assets/images/background/CustomBG2.png)"}}>
                    <div className="container">
                        <p className="slider-one__tag-line">Torchbearers for Syariah & Law.</p>
                        <h2 className="slider-one__title">Peguambela <br/> Peguamcara <br/> Peguam Syarie <br/> <span style={{color: '#D4AF37'}}>KELUARGA ANDA</span> </h2>
                        <p className="slider-one__text">Kami bersedia membantu individu dan syarikat korporat di Malaysia.<br/>  Segera rungkaikan isu perundangan yang membelenggu anda.</p>
                        
                        <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn slider-one__btn">Consultation<i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
                <div className="item slider-one__slide slider-one__slider-3" style={{backgroundImage: "url(assets/images/background/CustomBG3.png)"}}>
                    <div className="container">
                        <p className="slider-one__tag-line">Torchbearers for Syariah & Law.</p>
                        <h2 className="slider-one__title">Peguambela <br/> Peguamcara <br/> Peguam Syarie <br/> <span style={{color: '#D4AF37'}}>SYARIKAT ANDA</span> </h2>
                        <p className="slider-one__text">Kami bersedia membantu individu dan syarikat korporat di Malaysia.<br/>  Segera rungkaikan isu perundangan yang membelenggu anda.</p>
                        
                        <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn slider-one__btn">Consultation<i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
                <div className="item slider-one__slide slider-one__slider-4" style={{backgroundImage: "url(/assets/images/background/CustomBG4.png)"}}>
                    <div className="container">
                        <p className="slider-one__tag-line">Torchbearers for Syariah & Law.</p>
                        <h2 className="slider-one__title">Peguambela <br/> Peguamcara <br/> Peguam Syarie <br/> <span style={{color: '#D4AF37'}}>PERNIAGAAN ANDA</span> </h2>
                        <p className="slider-one__text">Kami bersedia membantu individu dan syarikat korporat di Malaysia.<br/>  Segera rungkaikan isu perundangan yang membelenggu anda. </p>
                        
                        <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn slider-one__btn">Consultation<i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
                <div className="item slider-one__slide slider-one__slider-5" style={{backgroundImage: "url(assets/images/background/CustomBG1.png)"}}>
                    <div className="container">
                        <p className="slider-one__tag-line">Torchbearers for Syariah & Law.</p>
                        <h2 className="slider-one__title">Peguambela <br/> Peguamcara <br/> Peguam Syarie <br/> <span style={{color: '#D4AF37'}}>PENGURUSAN HARTA PUSAKA</span> </h2>
                        <p className="slider-one__text">Kami bersedia membantu individu dan syarikat korporat di Malaysia.<br/>  Segera rungkaikan isu perundangan yang membelenggu anda.</p>
                        
                        <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn slider-one__btn">Consultation<i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
                <div className="item slider-one__slide slider-one__slider-6" style={{backgroundImage: "url(assets/images/background/CustomBG2.png)"}}>
                    <div className="container">
                        <p className="slider-one__tag-line">Torchbearers for Syariah & Law.</p>
                        <h2 className="slider-one__title">Peguambela <br/> Peguamcara <br/> Peguam Syarie <br/> <span style={{color: '#D4AF37'}}>FARAID</span> </h2>
                        <p className="slider-one__text">Kami bersedia membantu individu dan syarikat korporat di Malaysia.<br/>  Segera rungkaikan isu perundangan yang membelenggu anda.</p>
                        
                        <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn slider-one__btn">Consultation<i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
                <div className="item slider-one__slide slider-one__slider-7" style={{backgroundImage: "url(/assets/images/background/CustomBG3.png)"}}>
                    <div className="container">
                        <p className="slider-one__tag-line">Torchbearers for Syariah & Law.</p>
                        <h2 className="slider-one__title">Peguambela <br/> Peguamcara <br/> Peguam Syarie <br/> <span style={{color: '#D4AF37'}}>HIBAH</span> </h2>
                        <p className="slider-one__text">Kami bersedia membantu individu dan syarikat korporat di Malaysia.<br/>  Segera rungkaikan isu perundangan yang membelenggu anda. </p>
                        
                        <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn slider-one__btn">Consultation<i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
                <div className="item slider-one__slide slider-one__slider-8" style={{backgroundImage: "url(assets/images/background/CustomBG4.png)"}}>
                    <div className="container">
                        <p className="slider-one__tag-line">Torchbearers for Syariah & Law.</p>
                        <h2 className="slider-one__title">Peguambela <br/> Peguamcara <br/> Peguam Syarie <br/> <span style={{color: '#D4AF37'}}>WASIAT</span> </h2>
                        <p className="slider-one__text">Kami bersedia membantu individu dan syarikat korporat di Malaysia.<br/>  Segera rungkaikan isu perundangan yang membelenggu anda.</p>
                        
                        <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank" className="thm-btn slider-one__btn">Consultation<i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
                <div className="item slider-one__slide slider-one__slider-9" style={{backgroundImage: "url(assets/images/background/CustomBG1.png)"}}>
                    <div className="container">
                        <p className="slider-one__tag-line">Torchbearers for Syariah & Law.</p>
                        <h2 className="slider-one__title">Peguambela <br/> Peguamcara <br/> Peguam Syarie <br/> <span style={{color: '#D4AF37'}}>TUNTUTAN KEMALANGAN</span> </h2>
                        <p className="slider-one__text">Kami bersedia membantu individu dan syarikat korporat di Malaysia.<br/>  Segera rungkaikan isu perundangan yang membelenggu anda.</p>
                        
                        <a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank"className="thm-btn slider-one__btn">Consultation<i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
            <div className="slider-one__nav">
                <a className="slider-one__nav-left slide-one__left-btn" href="#">
                    <i className="fas fa-angle-left"></i>
                </a>
                <a className="slider-one__nav-right slide-one__right-btn" href="#">
                    <i className="fas fa-angle-right"></i>
                </a>
            </div>
        </section>
    </div>
      
    )
  }
  
  export default Home