import React from "react";
import Menu from '../layouts/Menu';
import {Link} from 'react-router-dom';
import ReactScrollableFeed from 'react-scrollable-feed';
// import Footer from '../layouts/Footer';

function About() {
    return (
        <div>
            <div className="page-wrapper">
                <Menu />

                <section
                    className="inner-banner inner-banner__overlay-two"
                    style={{
                        backgroundImage:
                            "url(assets/images/background/CustomBG3.png)",
                    }}
                >
                    <div className="container">
                        <p className="inner-banner__tag-line">Torchbearers for Syariah & Law</p>
                        <h2 className="inner-banner__title">Tentang Kami</h2>
                        <ul className="list-unstyled thm-breadcrumb">
                            <li>
                                <a href="index.html">Utama</a>
                            </li>
                            <li>Tentang Pasukan Kami</li>
                        </ul>
                    </div>
                </section>

                <section className="team-one team-one__home-two">
                    <div className="container">
                        <div className="block-title text-center">
                            <h3 className="block-title__title">PASUKAN KAMI</h3>
                            <p className="block-title__text-two">
                            KAMI MEMPUNYAI PASUKAN PEGUAM YANG BERPENGALAMAN DALAM MENGENDALIKAN KES-KES MAHKAMAH DI MALAYSIA
                            </p>
                        </div>

                        {/* <div className="row no-gutters">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="team-one__single"></div>
                                </div> */}
                           
                            {/* <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="team-one__single"></div>
                                </div>
                        </div> */}

                        <div className="row no-gutters">
                        {/* 1st Box - START */}
                        <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="team-one__single">
                                    <div className="team-one__image">
                                        <img
                                            src="assets/images/staff/en.Hariz.jpg"
                                            alt="Awesome Image"
                                        />
                                        <div className="team-one__image-hover">
                                            {/* <div className="team-one__social">
                                                <a href="#" className="fab fa-google-plus-g"></a>
                                                <a href="#" className="fab fa-twitter"></a>
                                                <a href="#" className="fab fa-facebook"></a>
                                                <a href="#" className="fab fa-linkedin-in"></a>
                                            </div> */}
                                            {/* <Link to="/attorney"> */}
                                            <ReactScrollableFeed>
                                            <a
                                                href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji."
                                                target="_blank" className="team-one__contact"
                                            >
                                            <div class="team-moto"><h6></h6></div><br/>
                                            <div class="team-title-details"><u><b>Pendidikan dan Pengalaman</b></u></div><br/><br/>
                                            <ul>
                                                <li>Ijazah Sarjana (Master) Undang-Undang Perbandingan (Master of Comparative Laws, MCL) dari Universiti Islam Antarabangsa Malaysia (UIAM).</li><br/>
                                                <li>Ijazah Sarjana Muda Undang-Undang Syariah (Kepujian) [LL.B(S)] dari Universiti Islam Antarabangsa Malaysia (UIAM).</li><br/>
                                                <li>Ijazah Sarjana Muda Undang-Undang (Kepujian) (LL.B) dari Universiti Islam Antarabangsa Malaysia (UIAM).</li><br/>
                                                <li>Kini menyambung pengajian dalam bidang PhD Undang-Undang bertumpu kepada Pentadbiran Aset Digital Islamik dan Syariah terhadap harta Muslim.</li><br/>
                                                <li>Memiliki Cert. of Mediation (Australia) & Cert. of Advance Mediation (Australia)</li><br/>
                                                <li>Berpengalaman dalam mengendalikan dan menguruskan kes litigasi sivil, jenayah, korporat serta syariah.</li><br/>
                                                <li>Merupakan peguam sivil dan syariah serta merupakan perunding dan Mediator bertauliah.</li><br/>
                                                <li>Mempunyai pengetahuan dalam bidang Undang-Undang Perbankan Islam dan Takaful, Undang-Undang Obligasi dalam Islam dan Pengurusan Harta Pusaka Muslim dan Faraid.</li><br/>
                                            </ul>
                                            </a>
                                            </ReactScrollableFeed>
                                            {/* </Link> */}
                                            {/* <a href="tel:222-333-8800" className="team-one__contact">
                                                Tel: <span>222 333 8800</span>
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <h3 className="team-one__name">Muhammad Hariz bin Md Yusoff</h3>
                                        <p className="team-one__designation">Peguam HARS</p>
                                    </div>
                                </div>
                            </div>
                            {/* 1st Box - END */}

                            {/* 2nd Box - START */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="team-one__single">
                                    <div className="team-one__image">
                                        <img
                                            src="assets/images/staff/_X6A9684(edit).jpg"
                                            alt="Awesome Image"
                                        />
                                        <div className="team-one__image-hover">
                                            {/* <div className="team-one__social">
                                            
                                            </div> */}
                                            <ReactScrollableFeed>
                                            <a
                                                href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji."
                                                target="_blank" className="team-one__contact"
                                            >
                                            <div class="team-moto"><h6></h6></div><br/>
                                            <div class="team-title-details"><u><b>Pendidikan dan Pengalaman</b></u></div><br/><br/>
                                            <ul>
                                                <li>Ijazah Sarjana (Master) Undang-Undang (LL.M) dari Universiti Teknologi Mara (UiTM).</li><br/>
                                                <li>Ijazah Sarjana Muda Undang-Undang (Kepujian) (LL.B) dari Universiti Teknologi Mara Shah Alam (UiTM).</li><br/>
                                                <li>Diploma Lepasan Ijazah Syariah & Amalan (DLSA) dari Universiti Teknologi Mara (UiTM).</li><br/>
                                                <li>Kini sedang menyambung pengajian di peringkat PhD Undang-Undang Jenayah di Universiti Islam Antarabangsa Malaysia (UIAM) bertumpu kepada isu Jenayah Pedofil.</li><br/>
                                                <li>Memiliki Cert. of Mediation (Australia) & Cert. of Advance Mediation (Australia)</li><br/>
                                                <li>Telah ditauliahkan sebagai Peguambela dan Peguamcara dan seterusnya ditauliahkan sebagai Peguam Syarie.</li><br/>
                                                <li>Merupakan perunding dan Mediator bertauliah.</li><br/>
                                                <li>Beliau mempunyai pengalaman dalam bidang hartanah, perbankan, jenayah dan juga syariah.</li><br/>
                                            </ul>
                                            </a>
                                            </ReactScrollableFeed>
                                            {/* <a href="tel:222-333-8800" className="team-one__contact">
                                                Tel: <span>222 333 8800</span>
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <h3 className="team-one__name">Nur Fatin Syakinah binti Kamarudin</h3>
                                        <p className="team-one__designation">Peguam HARS</p>
                                    </div>
                                </div>
                            </div>
                            {/* 2nd Box - END */}

                            {/* 3rd Box - START */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="team-one__single">
                                    <div className="team-one__image">
                                        <img
                                            src="assets/images/staff/_X6A9690.jpg"
                                            alt="Awesome Image"
                                        />
                                        <div className="team-one__image-hover">
                                            {/* <div className="team-one__social">
                                                <a href="#" className="fab fa-google-plus-g"></a>
                                                <a href="#" className="fab fa-twitter"></a>
                                                <a href="#" className="fab fa-facebook"></a>
                                                <a href="#" className="fab fa-linkedin-in"></a>
                                            </div> */}
                                            <ReactScrollableFeed>
                                            <a
                                                href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji."
                                                target="_blank" className="team-one__contact"
                                            >
                                            <div class="team-moto"><h6></h6></div><br/>
                                            <div class="team-title-details"><u><b>Pendidikan dan Pengalaman</b></u></div><br/><br/>
                                            <ul>
                                                <li>Ijazah Sarjana Muda Undang-Undang (Kepujian) (LL.B) dari Universiti Islam Antarabangsa Malaysia (UIAM).</li><br/>
                                                <li>Ijazah Sarjana Muda Undang-Undang Syariah (Kepujian) [LL.B(S)] dari Universiti Islam Antarabangsa Malaysia (UIAM).</li><br/>
                                                <li>Memiliki Cert. of Mediation (Australia)</li><br/>
                                                <li>Merupakan seorang Peguambela dan Peguamcara serta Peguam Syarie.</li><br/>
                                                <li>Beliau mempunyai pengalaman dalam bidang sivil litigasi, jenayah, tuntutan kemalangan yang melibatkan kecederaan peribadi, kecuaian perubatan dan hak-hak pekerja dan majikan berdasarkan Akta Kerja 1955, serta pendrafan kontrak-kontrak komersial.</li><br/>
                                                <li>Beliau juga mempunyai pengalaman dalam bidang syariah dan mahir berbahasa Arab dalam penggunaan di mahkamah syariah. </li><br/>
                                                <li>Merupakan seorang Mediator bertauliah.</li><br/>
                                            </ul>
                                            </a>
                                            </ReactScrollableFeed>
                                            {/* <a href="tel:222-333-8800" className="team-one__contact">
                                                Tel: <span>222 333 8800</span>
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <h3 className="team-one__name">Ahmad Khalil bin Md Rofiee</h3>
                                        <p className="team-one__designation">Peguam HARS</p>
                                    </div>
                                </div>
                            </div>
                            {/* 3rd Box - END */}

                            {/* 4th Box - START */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="team-one__single">
                                    <div className="team-one__image">
                                        <img
                                            src="assets/images/staff/_X6A9675.jpg"
                                            alt="Awesome Image"
                                        />
                                        <div className="team-one__image-hover">
                                            {/* <div className="team-one__social">
                                                <a href="#" className="fab fa-google-plus-g"></a>
                                                <a href="#" className="fab fa-twitter"></a>
                                                <a href="#" className="fab fa-facebook"></a>
                                                <a href="#" className="fab fa-linkedin-in"></a>
                                            </div> */}
                                            <ReactScrollableFeed>
                                            <a
                                                href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji."
                                                target="_blank" className="team-one__contact"
                                            >
                                            <div class="team-moto"><h6></h6></div><br/>
                                            <div class="team-title-details"><u><b>Pendidikan dan Pengalaman</b></u></div><br/><br/>
                                            <ul>
                                                <li>Ijazah Sarjana Muda Undang-Undang (Kepujian) (LL.B) dari Universiti Islam Antarabangsa Malaysia (UIAM).</li><br/>
                                                <li>Ijazah Sarjana Muda Undang-Undang Syariah (Kepujian) [LL.B(S)] dari Universiti Islam Antarabangsa Malaysia (UIAM).</li><br/>
                                                <li>Memiliki Cert. of Mediation (Australia)</li><br/>
                                                <li>Beliau Mahir dalam pertuturan dan menterjemah Bahasa Arab termasuk dokumen perundangan komersial dan perdagangan bagi urusan perniagaan dengan negara-negara Arab.</li><br/>
                                                <li>Seorang Peguambela dan Peguamcara serta Peguam Syarie.</li><br/>
                                                <li>Mempunyai pengalaman dalam bidang sivil, litigasi perbankan dan juga pusaka syariah serta merupakan Mediator bertauliah.</li><br/> 
                                            </ul>
                                            </a>
                                            </ReactScrollableFeed>
                                            {/* <a href="tel:222-333-8800" className="team-one__contact">
                                                Tel: <span>222 333 8800</span>
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <h3 className="team-one__name">Omar Mokhtar bin Hamdan</h3>
                                        <p className="team-one__designation">Peguam HARS</p>
                                    </div>
                                </div>
                            </div>
                            {/* 4th Box - END */}

                            {/* 5th Box - START */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="team-one__single">
                                    <div className="team-one__image">
                                        <img
                                            src="assets/images/staff/_X6A9698.jpg"
                                            alt="Awesome Image"
                                        />
                                        <div className="team-one__image-hover">
                                            {/* <div className="team-one__social">
                                                <a href="#" className="fab fa-google-plus-g"></a>
                                                <a href="#" className="fab fa-twitter"></a>
                                                <a href="#" className="fab fa-facebook"></a>
                                                <a href="#" className="fab fa-linkedin-in"></a>
                                            </div> */}
                                            <ReactScrollableFeed>
                                            <a
                                                href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji."
                                                target="_blank" className="team-one__contact"
                                            >
                                            <div class="team-moto"><h6></h6></div><br/>
                                            <div class="team-title-details"><u><b>Pendidikan dan Pengalaman</b></u></div><br/><br/>
                                            <ul>
                                            <li>Ijazah Sarjana Muda Syariah dan Undang-Undang dari Universiti Islam Antarabangsa Selangor (UIS).</li><br/>
                                            <li>Diploma Pascasiswazah Pentadbiran Kehakiman & Syariah (DAIJ) dari Universiti Islam Antarabangsa Malaysia(UIAM).</li><br/>
                                            <li>Beliau merupakan seorang Peguam Syarie dan berperanan dalam menganalisis jurnal dan makalah Syariah.</li><br/>
                                            <li>Berkemahiran dalam kes-kes Mal Syariah dan Jenayah Syariah.</li><br/>
                                            <li>Berpengalaman dalam litigasi Mahkamah Syariah dan konsultan syariah khusus dalam bidang penasihatan piawaian industri halal dan instrumen-instrumen dokumen syariah.</li><br/>
                                            </ul>
                                            </a>
                                            </ReactScrollableFeed>
                                            {/* <a href="tel:222-333-8800" className="team-one__contact">
                                                Tel: <span>222 333 8800</span>
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <h3 className="team-one__name">Nurul Tasnim binti Abd Rahman</h3>
                                        <p className="team-one__designation">Peguam HARS</p>
                                    </div>
                                </div>
                            </div>
                            {/* 5th Box - END */}

                            {/* 6th Box - START */}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="team-one__single">
                                    <div className="team-one__image">
                                        <img
                                            src="assets/images/staff/_X6A9681.jpg"
                                            alt="Awesome Image"
                                        />
                                        <div className="team-one__image-hover">
                                            {/* <div className="team-one__social">
                                                <a href="#" className="fab fa-google-plus-g"></a>
                                                <a href="#" className="fab fa-twitter"></a>
                                                <a href="#" className="fab fa-facebook"></a>
                                                <a href="#" className="fab fa-linkedin-in"></a>
                                            </div> */}
                                            <ReactScrollableFeed>
                                            <a
                                                href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji."
                                                target="_blank" className="team-one__contact"
                                            >
                                            <div class="team-moto"><h6></h6></div><br/>
                                            <div class="team-title-details"><u><b>Pendidikan dan Pengalaman</b></u></div><br/><br/>
                                            <ul>
                                            <li>Ijazah Sarjana Muda Undang-Undang (Kepujian) (LL.B) dari Universiti Islam Antarabangsa Malaysia (UIAM).</li><br/>
                                            <li>Beliau mempunyai pengetahuan yang mendalam dalam bidang pindahmilik hartanah, pengurusan hartanah, aset warisan dan juga pengunbahan wang haram.</li><br/>
                                            <li>Beliau bertindak untuk pelbagai institusi kewangan dalam litigasi perbankan yang melibatkan pembiayaan konvensional dan Islam, pertikaian sivil dan pemulihan aset.</li><br/>
                                            <li>Berpengalaman luas dalam memberi khidmat nasihat dalam perkara yang memerlukan diplomasi dan Consultation strategik yang berkesan dalam pertimbangan penyelesaian resolusi di luar mahkamah.</li><br/>
                                            <li>Seorang penyelidik yang berkebolehan, beliau pernah mewakili Universiti Islam Antarabangsa Malaysia (UIAM) dan Malaysia dalam pertandingan mooting Undang-Undang Antarabangsa dan menjadi jurulatih kepada beberapa pasukan mooting.</li><br/>
                                            <li>Beliau juga terlibat dalam beberapa kajian penyelidikan merangkumi pelbagai bidang seperti harta intelek, perkapalan, Undang-Undang Maritim, dan juga pengubahan wang haram.</li><br/>
                                            <li>Sumbangan beliau yang paling ketara telah diterbitkan di dalam buku Undang-Undang Pencegahan Pengubahan Wang Haram dan Pembiayaan Keganasan di Malaysia karya oleh Professor Dr. Norhashimah Mohd Yasin dan Professor Madya Dr.Mohd Yazid Zul Kepli.</li><br/>
                                            </ul>
                                            </a>
                                            </ReactScrollableFeed>
                                            {/* <a href="tel:222-333-8800" className="team-one__contact">
                                                Tel: <span>222 333 8800</span>
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <h3 className="team-one__name">Mustaqim bin Mohd Marsidi</h3>
                                        <p className="team-one__designation">Peguam HARS</p>
                                    </div>
                                </div>
                            </div>
                            {/* 6th Box - END*/}

                        </div>
                    </div>
                </section>

                {/* <section className="cta-one">
                    <div className="container text-center">
                        <h3 className="cta-one__title">
                            Need an Advice from Expert Lawyers? <br /> Get an Appointment
                            Today!
                        </h3>
                        <p className="cta-one__phone">
                            <i className="fa fa-phone-alt"></i>
                            Call us for a quick help!
                            <a href="tel:1-258-985-703">1-258-985-703</a>
                        </p>
                        <ul className="list-unstyled cta-one__contact">
                            <li>
                                <i className="far fa-envelope-open"></i>
                                <a href="mailto:example@mail.com">example@mail.com</a>
                            </li>
                            <li>
                                <i className="far fa-clock"></i>
                                Monday to Friday 9am - 6pm
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="case-form-one">
                    <div className="container">
                        <div
                            className="inner-container wow fadeInUp"
                            data-wow-duration="1500ms"
                        >
                            <div className="block-title text-center">
                                <h3 className="block-title__title">Get Free Case Evaluation</h3>
                                <p className="block-title__text-one">
                                    Proident sunt culpa officia deserunt mollit animd laborum
                                    perspicia natus <br /> error voluptatem qui acusantium
                                    doloremque laudan.
                                </p>
                            </div>
                            <form
                                action="http://ashik.templatepath.net/opklim-html-files/assets/inc/sendemail.php"
                                className="case-form-one__form contact-form-validated"
                            >
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="case-form-one__field">
                                            <input type="text" name="name" placeholder="Your Name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="case-form-one__field">
                                            <input type="text" name="email" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="case-form-one__field">
                                            <input type="text" name="phone" placeholder="Phone" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="case-form-one__field">
                                            <i className="far fa-calendar-alt"></i>
                                            <input
                                                type="text"
                                                name="date"
                                                className="datepicker"
                                                placeholder="Select Day"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="case-form-one__field">
                                            <select className="selectpicker" name="time">
                                                <option value="">Select Time</option>
                                                <option value="8.00-9.00">8.00-9.00</option>
                                                <option value="9.01-10.00">9.01-10.00</option>
                                                <option value="10.01-11.00">10.01-11.00</option>
                                                <option value="12.01-13.00">12.01-13.00</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="case-form-one__field">
                                            <select className="selectpicker" name="services">
                                                <option>Service Required</option>
                                                <option value="Real Estate Laws">
                                                    Real Estate Laws
                                                </option>
                                                <option value="Personal Injury">Personal Injury</option>
                                                <option value="Criminal Laws">Criminal Laws</option>
                                                <option value="Health Related Laws">
                                                    Health Related Laws
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="case-form-one__field">
                                            <textarea name="message" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="case-form-one__field text-center mb-0">
                                            <button
                                                type="submit"
                                                className="thm-btn case-form-one__btn"
                                            >
                                                book an appointment{" "}
                                                <i className="fa fa-long-arrow-alt-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="result"></div>
                        </div>
                    </div>
                </section>

                <div className="brand-one brand-one__about-page">
                    <div className="container">
                        <div
                            className="thm__owl-carousel owl-carousel owl-theme"
                            data-options='{"loop": true, "margin": 100, "nav": false, "dots": false, "autoWidth": false, "autoplay": true, "smartSpeed": 700, "autoplayTimeout": 5000, "autoplayHoverPause": true, "responsive": {"0": {"items": 2, "margin": 30 }, "480": {"items": 3, "margin": 40 }, "600": {"items": 4, "margin": 50 }, "991": {"items": 5 }, "1000": {"items": 6 }, "1200": {"items": 6 } } }'
                        >
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-1.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-2.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-3.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-4.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-5.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-6.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-1.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-2.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-3.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-4.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-5.png"
                                    alt="Awesome Image"
                                />
                            </div>
                            <div className="item">
                                <img
                                    src="assets/images/brand/brand-1-6.png"
                                    alt="Awesome Image"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <section className="mailchimp-one">
                    <div className="container">
                        <div className="row align-items-center no-gutters">
                            <div className="col-xl-5">
                                <div className="mailchimp-one__content">
                                    <h3 className="mailchimp-one__title">
                                        Join Our List To Stay Intouch
                                    </h3>
                                    <p className="mailchimp-one__text">
                                        Stay in Touch and enim adminim veniam quis nostrud
                                        exercitation ullamco
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <form
                                    action="#"
                                    className="mailchimp-one__form mc-form"
                                    data-url="https://xyz.us18.list-manage.com/subscribe/post?u=20e91746ef818cd941998c598&amp;id=cc0ee8140e"
                                >
                                    <i className="fas fa-envelope-open mailchimp-one__icon"></i>
                                    <input
                                        type="email"
                                        id="mc-email"
                                        placeholder="Email address ..."
                                        name="email"
                                    />
                                    <button type="submit" className="thm-btn mailchimp-one__btn">
                                        subscribe <i className="fas fa-long-arrow-alt-right"></i>
                                    </button>
                                </form>
                                <div className="mc-form__response"></div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <footer className="site-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-6">
                                <div className="footer-widget">
                                    <a href="index.html" className="footer-widget__logo">
                                        <img
                                            src="assets/images/resources/logo-light-1-2.png"
                                            alt="Awesome Image"
                                        />
                                    </a>
                                    <p className="footer-widget__text">
                                        Ipsum dolor sit amet, consecterelit, sed eiusmod <br />
                                        tempor incididunt labore et dolore magnas. Lorem <br />
                                        ipsum dolor sit amet, consectetur.
                                    </p>

                                    <p className="footer-widget__text">
                                        Do eiusmod tempor incididunt ut labore et dolore <br />
                                        magna aliquat enim ad minim veniam.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-6">
                                <div className="footer-widget">
                                    <h3 className="footer-widget__title">Practice Areas</h3>
                                    <ul className="footer-widget__links list-unstyled">
                                        <li>
                                            <a href="#">Real Estate Laws</a>
                                        </li>
                                        <li>
                                            <a href="#">Personal Injury</a>
                                        </li>
                                        <li>
                                            <a href="#">Criminal Laws</a>
                                        </li>
                                        <li>
                                            <a href="#">Health & Insurance</a>
                                        </li>
                                        <li>
                                            <a href="#">Domestic Voilance</a>
                                        </li>
                                        <li>
                                            <a href="#">Fraud & Theft</a>
                                        </li>
                                        <li>
                                            <a href="#">Transportation Laws</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6">
                                <div className="footer-widget footer-widget__quick-link">
                                    <h3 className="footer-widget__title">Quick Links</h3>
                                    <ul className="footer-widget__links list-unstyled">
                                        <li>
                                            <a href="#">About Law Firm</a>
                                        </li>
                                        <li>
                                            <a href="#">Our Attorneys</a>
                                        </li>
                                        <li>
                                            <a href="#">Recent cases</a>
                                        </li>
                                        <li>
                                            <a href="#">Contact us</a>
                                        </li>
                                        <li>
                                            <a href="#">Book Appointment</a>
                                        </li>
                                        <li>
                                            <a href="#">Latest News</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6">
                                <div className="footer-widget">
                                    <h3 className="footer-widget__title">Head Office</h3>
                                    <p className="footer-widget__text">
                                        15 Pillis Avenue, Southern Street <br /> Camron, Florida FL
                                        33069
                                    </p>
                                    <p className="footer-widget__text footer-widget__contact-info">
                                        <span>Helpline: </span>{" "}
                                        <a href="tel:1-258-985-703">1-258-985-703</a>
                                    </p>
                                    <p className="footer-widget__text">
                                        Mon to Fri : 09:00 am - 18:00 pm <br /> Sat : 08:00 am -
                                        16:00 pm
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="site-footer__bottom">
                    <div className="container">
                        <p className="site-footer__copy">
                            © 2019 OPKLIM Law Firm. All Rights Reserved.{" "}
                            <a href="#">Privacy Notice</a>
                        </p>

                        <div className="site-footer__social">
                            <a href="#" className="fab fa-twitter"></a>
                            <a href="#" className="fab fa-google-plus-g"></a>
                            <a href="#" className="fab fa-facebook"></a>
                            <a href="#" className="fab fa-instagram"></a>
                            <a href="#" className="fab fa-youtube"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="side-menu__block">
                <div className="side-menu__block-overlay custom-cursor__overlay">
                    <div className="cursor"></div>
                    <div className="cursor-follower"></div>
                </div>
                <div className="side-menu__block-inner ">
                    <a href="index.html">
                        <img
                            src="assets/images/resources/logo-dark-1-1.png"
                            alt="Awesome Image"
                        />
                    </a>
                    <div className="side-menu__block-about">
                        <h3 className="side-menu__block__title">About Us</h3>
                        <p className="side-menu__block-about__text">
                            We must explain to you how all seds this mistakens idea off
                            denouncing pleasures and praising pain was born and I will give
                            you a completed accounts off the system and{" "}
                        </p>
                        <a href="#" className="thm-btn side-menu__block-about__btn">
                            Get Consultation
                        </a>
                    </div>
                    <hr className="side-menu__block-line" />
                    <div className="side-menu__block-contact">
                        <h3 className="side-menu__block__title">Contact Us</h3>
                        <ul className="side-menu__block-contact__list">
                            <li className="side-menu__block-contact__list-item">
                                <i className="fa fa-map-marker"></i>
                                Rock St 12, Newyork City, USA
                            </li>
                            <li className="side-menu__block-contact__list-item">
                                <i className="fa fa-phone"></i>
                                <a href="tel:526-236-895-4732">(526) 236-895-4732</a>
                            </li>
                            <li className="side-menu__block-contact__list-item">
                                <i className="fa fa-envelope"></i>
                                <a href="mailto:example@mail.com">example@mail.com</a>
                            </li>
                            <li className="side-menu__block-contact__list-item">
                                <i className="fa fa-clock"></i>
                                Week Days: 09.00 to 18.00 Sunday: Closed
                            </li>
                        </ul>
                    </div>
                    <p className="side-menu__block__text site-footer__copy-text">
                        <a href="#">OPKLIM</a> <i className="far fa-copyright"></i> 2019 All
                        Right Reserved
                    </p>
                </div>
            </div>
            <div className="search-popup">
                <div className="search-popup__overlay custom-cursor__overlay">
                    <div className="cursor"></div>
                    <div className="cursor-follower"></div>
                </div>
                <div className="search-popup__inner">
                    <form action="#" className="search-popup__form">
                        <input
                            type="text"
                            name="search"
                            placeholder="Type here to Search...."
                        />
                        <button type="submit">
                            <i className="fa fa-search"></i>
                        </button>
                    </form>
                </div>
            </div> */}

            <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
                <i className="fa fa-angle-up"></i>
            </a>
        </div>
    </div>
    );
}

export default About;
