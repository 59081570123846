import React from 'react';
import Menu from '../layouts/Menu';
import {NavLink} from 'react-router-dom';

const handleClick = () => {
    // force a re-render
    this.forceUpdate();
  };

function Contact() {
  return (
    <div>
       <div className="page-wrapper">
        {/* <div className="topbar-two">
            <div className="container">
                <div className="topbar-two__left">
                    <a className="topbar-two__link" href="tel:1-258-985-703"><i className="fa fa-phone-alt"></i>Get Free Advice <span>1-258-985-703</span></a>
                    <a className="topbar-two__link" href="tel:1-258-985-703"><i className="fa fa-clock"></i>Monday to Friday 9am - 6pm</a>
                    <a className="topbar-two__link" href="mailto:support@example.com"><i className="fa fa-envelope-open"></i>support@example.com</a>
                </div>
                <div className="topbar-two__right">
                    <a href="#" className="thm-btn header-booking__btn">book an appointment <i className="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div> */}

        <Menu />
        <section className="inner-banner">
            <div className="container">
                <p className="inner-banner__tag-line">Torchbearers for Syariah & Law</p>
                <h2 className="inner-banner__title">Hubungi Kami</h2>
                <ul className="list-unstyled thm-breadcrumb">
                    <li><NavLink onClick={handleClick} to="/">Utama</NavLink></li>
                    <li>Hubungi</li>
                </ul>
            </div>
        </section>
        <section className="contact-one">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-7">
                        <form action="http://ashik.templatepath.net/opklim-html-files/assets/inc/sendemail.php" className="contact-one__form contact-form-validated">
                            <div className="block-title text-left">
                                <h3 className="block-title__title">Send us a Message</h3>
                                <p className="block-title__text-two">Proident sunt culpa officia deserunt mollit animd laborum perspicia natus <br/> error voluptatem qui acusantium doloremque laudan.</p>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="case-form-one__field">
                                        <input type="text" name="name" placeholder="Your Name"/>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="case-form-one__field">
                                        <input type="text" name="email" placeholder="Email"/>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="case-form-one__field">
                                        <input type="text" name="subject" placeholder="Subject"/>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="case-form-one__field">
                                        <textarea name="message" placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="case-form-one__field text-left mb-0">
                                        <button type="submit" className="thm-btn case-form-one__btn">Send Message <i className="fa fa-long-arrow-alt-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> */}
                    <div className="col-lg-6">
                        <div className="contact-one__info">
                            {/* <div className="block-title text-left">
                                <h3 className="block-title__title">Our Location</h3>
                                <p className="block-title__text-two">Miventore veritatis et quasi architecto beatae vitae dicta sunt <br/> nemo enim consequuntur magni dolores eos.</p>
                            </div> */}
                            <div className="contact-one__info-single">
                                <h3 className="contact-one__info-title">Lokasi</h3>
                                <p className="contact-one__info-text"><a href="https://www.waze.com/ul?q=HARIZ%20SYAKINAH%20&%20CO" target="_blank">L-07-01, Level 7, Block L Solaris Mont Kiara, <br/> No. 2, Jalan Solaris Mont Kiara, 50480, Kuala Lumpur.</a></p>
                                {/* <a href="https://showtheway.io/to/3.189561,101.690519?name=HARIZ%20SYAKINAH%20%26%20CO" target="_blank" style="color:#32CCFD;">
                                    <i class="fas fa-map-marker-alt fa-3x"></i>
                                    <p></p>
                                    <p class="justify-content-center"><span>Address:</span> No. 8-1(B), Pusat Komersial Sinar, Jalan Sinar Sentul 1, Sentul, 51100 Kuala Lumpur</p>
                                </a> */}
                            </div>
                            <div className="contact-one__info-single">
                                <h3 className="contact-one__info-title">Emel</h3>
                                <p className="contact-one__info-text"><a href="mailto:hsc.kanun@gmail.com">hsc.kanun@gmail.com</a></p>
                            </div>
                            <div className="contact-one__info-single">
                                <h3 className="contact-one__info-title">Waktu Operasi</h3>
                                <p className="contact-one__info-text">Isnin - Jumaat : 09:00 am - 05:00 pm</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="contact-one_info">
                        <div className="contact-one__info-single">
                                <h3 className="contact-one__info-title">Telefon</h3>
                                <p className="contact-one__info-text">Hubungi<a href="tel:+6013-7315868"><strong>+6013-7315868</strong></a></p>
                            </div>
                            <div className="contact-one__info-single">
                                <h3 className="contact-one__info-title">Whatssap</h3>
                                <p className="contact-one__info-text"><a href="https://wa.me/60137315868?text=Saya berminat mendapatkan khidmat guaman HARS.%0AMohon temujanji." target="_blank">Tekan disini untuk whatssap</a></p>
                            </div>
                            <div className="contact-one__info-single">
                                <div className="contact-one__info-social">
                                    {/* <a href="#" className="fab fa-google-plus-g"></a> */}
                                    {/* <a href="#" className="fab fa-twitter"></a> */}
                                    <a href="https://www.facebook.com/HarizSyakinahCo/" className="fab fa-facebook"></a>
                                    <a href="https://instagram.com/hariz_syakinah_official?igshid=1d8zxdgmpo891" className="fab fa-instagram"></a>
                                    {/* <a href="#" className="fab fa-linkedin-in"></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <iframe src="https://maps.google.com/maps?q=HARIZ%20SYAKINAH%20&%20CO&t=&z=15&ie=UTF8&iwloc=&output=embed" className="google-map__contact" allowfullscreen></iframe>

        
        </div>
    </div>
  )
}

export default Contact