import React from 'react';
import Menu from '../layouts/Menu';
// import { NavLink } from "react-router-dom";

function Maintenance() {
    const handleClick = () => {
        // force a re-render
        this.forceUpdate();
      };
  return (
    <div>
        <Menu />
        <section className="inner-banner">
            <div className="container">
          <p className="desc font-white"></p>
                <p className="inner-banner__tag-line">Torchbearers for Syariah & Law</p>
                <br/><br/><br/>
                <h1 className="inner-banner__tutle">Coming Soon</h1>
                {/* <h2 className="inner-banner__tutle"><b>Coming Soon</b></h2> */}
                <p className="inner-banner__text">This page is under maintenance. We should be back shortly.<br/>Thank you for your patience.</p>
                {/* <ul className="list-unstyled thm-breadcrumb">
                    <li><NavLink onClick={handleClick} to="/">Utama</NavLink></li>
                    <li>Hubungi</li>
                </ul> */}
            </div>
        </section>
        
    </div>
  )
}

export default Maintenance;